import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiList, RouteList } from '../RouteList';

const TableSizes = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(ApiList.listTables)
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching table sizes:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className="flex justify-center items-center h-screen"><p>Loading...</p></div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Table Sizes</h1>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Table Name</th>
              <th>Size</th>
              <th>Row Count</th>
            </tr>
          </thead>
          <tbody>
            {data.map((table, index) => (
              <tr
                className="hover:bg-gray-100 group"
                key={index}
                
              >
                <td>{table.tableName}</td>
                <td>{table.size}</td>
                <td 
                  className="flex justify-between items-center"
                  // onClick={() => navigate(`/table/${table.tableName}`)}
                >
                  {table.rowCount}
                  <div className='group-hover:opacity-100 opacity-0'>

                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click when button is clicked
                        navigate(`${RouteList.showTableAction}${table.tableName}`);
                      }}
                      className="btn btn-success btn-sm "
                    >
                      View
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
};

export default TableSizes;
