import React, { useState, useEffect } from 'react';
import { ApiList } from '../RouteList';

const TaskDashboard = () => {
  const [tasks, setTasks] = useState([]);
  const [runningTasks, setRunningTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingRunningTasks, setLoadingRunningTasks] = useState(true);

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    fetchRunningTasks();
  }, [])

  const fetchTasks = async () => {
    try {
      const response = await fetch(ApiList.listTasks);
      const data = await response.json();
      setTasks(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setLoading(false);
    }
  };

  const fetchRunningTasks = async () => {
    try {
      const response = await fetch(ApiList.listScheduledTasks);
      const data = await response.json();
      setRunningTasks(data);
      setLoadingRunningTasks(false);
    } catch (error) {
      console.error('Error fetching running tasks:', error);
      setLoadingRunningTasks(false);
    }
  };

  const updateTask = async (id, updates) => {
    try {
      const response = await fetch(`${ApiList.thisTask}${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updates),
      });
      if (response.ok) {
        fetchTasks();
      } else {
        console.error('Error updating task');
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const runTask = async (taskName) => {
    try {
      updateTaskEnabledStatusByName({taskName: taskName, enabled: true});
      const response = await fetch(ApiList.scheduleTask, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ taskName, cronTime: '*/1 * * * *' }), // example cronTime
      });
      if (response.ok) {
        fetchTasks();
        fetchRunningTasks();
      } else {
        console.error('Error running task');
        updateTaskEnabledStatusByName({taskName: taskName, enabled: false});

      }
    } catch (error) {
      console.error('Error running task:', error);
      updateTaskEnabledStatusByName({taskName: taskName, enabled: false});

    }
  };

  const terminateTask = async (taskName) => {
    try {
      updateTaskEnabledStatusByName({taskName: taskName, enabled: false});
      const response = await fetch(ApiList.scheduleStop, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ taskName }),
      });
      if (response.ok) {
        fetchTasks();
        fetchRunningTasks();
      } else {
        console.error('Error terminating task');
        updateTaskEnabledStatusByName({taskName: taskName, enabled: true});
      }
    } catch (error) {
      console.error('Error terminating task:', error);
      updateTaskEnabledStatusByName({taskName: taskName, enabled: true});
    }
  };

  const handleFrequencyChange = (id, frequency) => {
    let cronTime;
    switch (frequency) {
      case 1:
        cronTime = '*/1 * * * *';
        break;
      case 5:
        cronTime = '*/5 * * * *';
        break;
      case 10:
        cronTime = '*/10 * * * *';
        break;
      case 30:
        cronTime = '*/30 * * * *';
        break;
      case 60:
        cronTime = '0 * * * *';
        break;
      case 120:
        cronTime = '0 */2 * * *';
        break;
      case 180:
        cronTime = '0 */3 * * *';
        break;
      case 360:
        cronTime = '0 */6 * * *';
        break;
      case 720:
        cronTime = '0 */12 * * *';
        break;
      case 1440:
        cronTime = '0 0 * * *';
        break;
      case 2880:
        cronTime = '0 0 */2 * *';
        break;
      default:
        cronTime = '*/1 * * * *';
        break;
    }

    updateTask(id, { frequency, cronTime });
  };

  const updateTaskEnabledStatusByName = ({taskName, enabled}) => {
    const updatedTasks = tasks.map(task => 
      task.name === taskName ? { ...task, enabled } : task
    );
    setTasks(updatedTasks);
  };

  if (loading || loadingRunningTasks) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Task Dashboard</h1>

      <h2 className="text-xl font-bold mb-4">All Tasks</h2>
      <table className="min-w-full bg-white mb-4">
        <thead>
          <tr>
            <th className="py-2 text-left">Task Name</th>
            <th className="py-2 text-left">Frequency (minutes)</th>
            <th className="py-2 text-left">Status</th>
            <th className="py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(task => (
            <tr key={task.id}>
              <td className="py-2">{task.readable_name}</td>
              <td className="py-2">
                <select
                  value={task.frequency}
                  onChange={(e) => handleFrequencyChange(task.id, Number(e.target.value))}
                  className="border rounded px-2 py-1"
                >
                  <option value={1}>1 minute</option>
                  <option value={5}>5 minutes</option>
                  <option value={10}>10 minutes</option>
                  <option value={30}>30 minutes</option>
                  <option value={60}>1 hour</option>
                  <option value={120}>2 hours</option>
                  <option value={180}>3 hours</option>
                  <option value={360}>6 hours</option>
                  <option value={720}>12 hours</option>
                  <option value={1440}>1 day</option>
                  <option value={2880}>2 days</option>
                </select>
              </td>
              <td className="py-2">{task.status}</td>
              <td className="py-2 flex space-x-2">
                {
                  task.enabled === false
                    ?
                    <button
                      onClick={() => runTask(task.name)}
                      className="btn btn-success btn-sm bg-blue-500 w-full"
                    >
                      Run
                    </button>
                    :
                    <button
                      onClick={() => terminateTask(task.name)}
                      className="btn btn-outline btn-error btn-sm w-full"
                    >
                      Terminate
                    </button>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <RunningTasks runningTasks={runningTasks} />
      
    </div>
  );
};


const RunningTasks = ({ runningTasks }) => {
  
  const handleIntervalChange = (selectedInterval: number) => {
    switch (selectedInterval) {
      case 1:
        return ("1 minute");
      case 5:
        return ("5 minutes");
      case 10:
        return ("10 minutes");
      case 30:
        return ("30 minutes");
      case 60:
        return ("1 hour");
      case 120:
        return ("2 hours");
      case 180:
        return ("3 hours");
      case 360:
        return ("6 hours");
      case 720:
        return ("12 hours");
      case 1440:
        return ("1 day");
      case 2880:
        return ("2 days");
      default:
        return("Unknown interval");
    }
  };

  return (
    <>
      <h2 className="text-xl font-bold mb-4">Running Tasks</h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 text-left">Task Name</th>
            <th className="py-2 text-left">Repeat Every</th>
            <th className="py-2 text-left">nb Executed</th>
            <th className="py-2 text-left">nb Items</th>
            <th className="py-2 text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          {runningTasks.map(task => (
            <tr key={task.taskName}>
              <td className="py-2">{task.taskName}</td>
              <td className="py-2">{ handleIntervalChange(task.frequency as number)}</td>
              <td className="py-2">{task.executionCount}</td>
              <td className="py-2">{task.nb_videos_affected}</td>
              <td className="py-2">{task.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default TaskDashboard;
