import { Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

interface LogData {
  hour: string;
  count: number;
  average_response_time: number;
}

const SearchPerformanceChart: React.FC = () => {
  const [data, setData] = useState<LogData[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/admin/search_performance_analytics');
        const result = await response.json();

        const hours = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`);

        const formattedData = hours.map(hour => {
          const matchingData = result.find((item: any) => new Date(item.hour).getHours() === parseInt(hour, 10));
          return {
            hour,
            count: matchingData ? matchingData.count : 0,
            average_response_time: matchingData ? parseFloat(matchingData.average_response_time).toFixed(2) : 0
          };
        });

        setData(formattedData as any);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching search performance analytics:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const customTooltip = ({ payload, label }: any) => {
    if (payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white p-4 rounded">
          <p className="label">{`Hour: ${label}`}</p>
          {payload.map((item: any, index: number) => (
            <p key={index} style={{ color: item.color }}>
              {`${item.name}: ${item.value} ${item.name === 'Number of API Requests' ? '' : 'ms'}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        {isMobile ? (
          <BarChart layout="vertical" data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="hour" type="category" />
            <Tooltip content={customTooltip} />
            <Legend />
            <Bar dataKey="count" fill="#8884d8" name="Number of API Requests" />
            <Bar dataKey="average_response_time" fill="#82ca9d" name="Avg Response Time (ms)" />
          </BarChart>
        ) : (
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hour" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" label={{ value: 'Number of API Requests', angle: -90, position: 'insideLeft' }} />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" label={{ value: 'Avg Response Time (ms)', angle: -90, position: 'insideRight' }} />
            <Tooltip content={customTooltip} />
            <Legend />
            <Bar yAxisId="left" dataKey="count" fill="#8884d8" name="Number of API Requests" />
            <Bar yAxisId="right" dataKey="average_response_time" fill="#82ca9d" name="Avg Response Time (ms)" />
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default SearchPerformanceChart;
