import { Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './ag-grid-theme-builder.css';
import { ApiList, RouteList } from '../RouteList';

const TableDetails = () => {
  const { tableName } = useParams();
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPage, setGoToPage] = useState(1);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newRowData, setNewRowData] = useState({});
  const gridRef = useRef(null);

  const tablesAllowingAddRow = ['keywords', 'blacklisted_channels', 'blacklisted_keywords']; // Replace with actual table names

  const fetchTableData = (page, size, sortModel = [], filterModel = {}) => {
    const sortField = sortModel.length ? sortModel[0].colId : null;
    const sortOrder = sortModel.length ? sortModel[0].sort : null;

    fetch(`${ApiList.thisTable}${tableName}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        page: page.toString(),
        pageSize: size.toString(),
        sortField,
        sortOrder,
        filter: filterModel,
      }),
    })
      .then(response => response.json())
      .then(data => {
        setRowData(data.rows);
        setTotalRows(data.total);
        setColumnDefs(data.columns.map(col => {
          const isNumeric = ['integer', 'bigint', 'decimal', 'float', 'double', 'numeric'].includes(col.type);
          const isTimestamp = ['timestamp', 'datetime', 'timestamp with time zone'].includes(col.type);
          return {
            headerName: col.name,
            field: col.name,
            editable: col.name !== 'id',
            sortable: true,
            filter: isNumeric ? 'agNumberColumnFilter' : 'agTextColumnFilter',
            filterParams: isNumeric
              ? {
                  filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                  debounceMs: 200,
                  suppressAndOrCondition: true,
                }
              : isTimestamp
              ? {
                  comparator: (filterLocalDateAtMidnight, cellValue) => {
                    const cellDate = new Date(cellValue);
                    if (cellDate < filterLocalDateAtMidnight) return -1;
                    if (cellDate > filterLocalDateAtMidnight) return 1;
                    return 0;
                  },
                }
              : null,
            cellEditor: isNumeric ? 'agNumberCellEditor' : isTimestamp ? 'agDateCellEditor' : 'agTextCellEditor',
            cellEditorParams: isNumeric
              ? { useFormatter: true }
              : isTimestamp
              ? { useFormatter: true, filter: 'agDateColumnFilter' }
              : null,
            valueParser: isNumeric ? 'Number(newValue)' : undefined,
            type: isNumeric ? 'numeric' : isTimestamp ? 'date' : 'text',
            nullable: col.nullable,
          };
        }));
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    fetchTableData(currentPage, pageSize, sortModel, filterModel);
  }, [tableName, currentPage, pageSize, sortModel, filterModel]);

  const isNumeric = value => !isNaN(value) && !isNaN(parseFloat(value));

  const onCellValueChanged = event => {
    const updatedData = event.data;
    const colDef = event.column.colDef;
    const fieldValue = updatedData[event.column.colId];
    const columnType = colDef.type;

    const isNullable = colDef.nullable;

    if (!isNullable && (fieldValue === null || fieldValue === undefined || fieldValue === '')) {
      event.node.setDataValue(event.column.colId, event.oldValue);
      alert(`Cell value for ${event.column.colId} cannot be empty`);
      return;
    }

    if (columnType === 'numeric' && !isNumeric(fieldValue)) {
      event.node.setDataValue(event.column.colId, event.oldValue);
      alert('Cell value must be a valid number and cannot be empty');
      return;
    }

    if (columnType === 'date' && isNaN(Date.parse(fieldValue))) {
      event.node.setDataValue(event.column.colId, event.oldValue);
      alert('Cell value must be a valid date');
      return;
    }

    fetch(`${ApiList.thisTable}${tableName}/${updatedData.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Data updated successfully:', data);
      })
      .catch(error => console.error('Error updating data:', error));
  };

  const onSortChanged = event => {
    let targetedColumn = event.columns[event.columns.length - 1];
    setSortModel([targetedColumn]);
  };

  const onFilterChanged = event => {
    const newFilterModel = event.api.getFilterModel();
    setFilterModel(newFilterModel);
  };

  const resetFilters = () => {
    gridRef.current.api.setFilterModel(null);
    setFilterModel({});
  };

  const deleteSelectedRows = () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedNodes.map(node => node.data.id);

    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete the selected rows?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            fetch(`${ApiList.thisTable}${tableName}/delete`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ ids: selectedIds }),
            })
              .then(response => response.json())
              .then(data => {
                console.log('Rows deleted successfully:', data);
                fetchTableData(currentPage, pageSize, sortModel, filterModel); // Refresh the table data
              })
              .catch(error => console.error('Error deleting rows:', error));
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewRowData(prevState => ({ ...prevState, [name]: value }));
  };

  const addNewRow = () => {
    // Remove 'id', 'created_at', and 'updated_at' fields before sending to the server
    const sanitizedNewRowData = Object.fromEntries(
      Object.entries(newRowData).filter(([key]) => 
        key !== 'id' 
        && key !== 'created_at' 
        && key !== 'updated_at'
      )
    );

    fetch(`${ApiList.thisTable}${tableName}/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sanitizedNewRowData),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Row added successfully:', data);
        fetchTableData(currentPage, pageSize, sortModel, filterModel); // Refresh the table data
        closeModal();
      })
      .catch(error => console.error('Error adding row:', error));
  };

  const totalPages = Math.ceil(totalRows / pageSize);

  const handleGoToPageChange = e => {
    setGoToPage(Number(e.target.value));
  };

  const handleGoToPageSubmit = e => {
    e.preventDefault();
    const page = Math.max(1, Math.min(totalPages, Number(goToPage)));
    setCurrentPage(page);
  };

  return (
    <div className="container mx-auto p-4">
      <div>
        <div className="breadcrumbs text-sm">
          <ul>
            <li>
              <Link to={RouteList.tableList}>
                <a>Tables</a>
              </Link>
            </li>
            <li> {tableName}</li>
          </ul>
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: 500, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          rowSelection="multiple"
          onCellValueChanged={onCellValueChanged}
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
            sortable: true,
            filter: true,
            editable: true,
            enableCellChangeFlash: true,
          }}
          enableRangeSelection={true}
          pagination={false}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={10}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressSideButtons: true,
                  suppressColumnMove: true,
                  suppressColumnFilter: true,
                  suppressColumnSelectAll: true,
                  suppressColumnExpandAll: true,
                },
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              },
            ],
            defaultToolPanel: '', // Hide the sidebar by default
          }}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
        />
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4 mt-4">
          <div className="flex flex-wrap items-center gap-4">
            <div className='flex gap-3 justify-between'>
              <button className="btn btn-secondary btn-sm" onClick={resetFilters}>
                Reset Filters
              </button>
              <button className="btn btn-danger btn-sm" onClick={deleteSelectedRows}>
                Delete Selected Rows
              </button>
            </div>
            {tablesAllowingAddRow.includes(tableName) && (
              <button className="btn btn-primary btn-sm" onClick={openModal}>
                Add New Row
              </button>
            )}
            <div className='flex gap-2 justify-between'>
              <button
                className="btn btn-secondary btn-sm"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                className="btn btn-success btn-sm"
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
          <div className="flex flex-wrap items-center gap-2 justify-between">
            <label className="flex items-center space-x-2">
              <span>Items per page:</span>
              <select
                className="select select-bordered select-sm"
                value={pageSize}
                onChange={e => setPageSize(Number(e.target.value))}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </label>
            <form onSubmit={handleGoToPageSubmit} className="flex items-center gap-2 justify-between">
              <label className="flex items-center space-x-2">
                <span>Go to page:</span>
                <input
                  type="number"
                  className="input input-bordered w-16 input-sm"
                  value={goToPage}
                  onChange={handleGoToPageChange}
                  min="1"
                  max={totalPages}
                />
              </label>
              <button type="submit" className="btn btn-success btn-sm">
                Go
              </button>
            </form>
          </div>
        </div>
      </div>

      <Modal open={isModalOpen} onClose={closeModal} center>
        <h2>Add New Row</h2>
        {columnDefs
          .filter(col => col.field !== 'id' 
            && col.field !== 'created_at' 
            && col.field !== 'updated_at'
            && col.field !== 'nb_video_found'
            && col.field !== 'nb_tries'
            && col.field !== 'nb_blacklisted_videos'
          )
          .map(col => (
            <div key={col.field} className="mb-2">
              <label>{col.headerName}</label>
              <input
                type="text"
                name={col.field}
                value={newRowData[col.field] || ''}
                onChange={handleInputChange}
                className="input input-bordered w-full"
              />
            </div>
          ))}
        <button onClick={addNewRow} className="btn btn-primary mt-2">
          Add Row
        </button>
        <button onClick={closeModal} className="btn btn-secondary mt-2">
          Cancel
        </button>
      </Modal>
    </div>
  );
};

export default TableDetails;
