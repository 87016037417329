import 'daisyui/dist/full.css'; // Make sure you have DaisyUI installed
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import { MdOutlineDisplaySettings, MdSpaceDashboard } from "react-icons/md";
import { FaDatabase, FaTasks } from "react-icons/fa";
import About from './screens/About';
import TableSizes from './screens/TableSizes';
import Home from './screens/Home';
import Environment from './screens/Environment';
import DataViewer from './screens/DataViewer';
import TaskDashboard from './screens/TaskDashboard';
import TableDetails from './screens/TableDetails';
import ErrorPage from './screens/ErrorPage';
import { RouteList } from './RouteList'; // Import your routes object
import { AuthProvider, useAuth } from './context/AuthContext';
import Register from './screens/auth/Register';
import Login from './screens/auth/Login'; // Correct import for Login
import ProtectedRoute from './ProtectedRoute'; // Assuming ProtectedRoute is in components folder
import { BiLogOut } from "react-icons/bi";
import ResetPassword from './screens/auth/ResetPassword';

function Main() {
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const location = useLocation(); // Get the current location
  const auth = useAuth();

  const menuItems = [
    { to: RouteList.home, icon: <MdSpaceDashboard size={20} className='text-gray-500 group-hover:text-gray-900 transition duration-75' />, label: "Home" },
    { to: RouteList.tableList, icon: <FaDatabase size={20} className='text-gray-500 group-hover:text-gray-900 transition duration-75' />, label: "Table Sizes" },
    // { to: RouteList.environment, icon: <MdOutlineDisplaySettings size={20} className='text-gray-500 group-hover:text-gray-900 transition duration-75' />, label: "Environment" },
    { to: RouteList.tasks, icon: <FaTasks size={20} className='text-gray-500 group-hover:text-gray-900 transition duration-75' />, label: "Tasks" },
    { to: '/logout', icon: <BiLogOut size={20} className='text-gray-500 group-hover:text-gray-900 transition duration-75' />, label: "Logout", action: () => auth.logout() },
  ];

  return (
    <div className="flex flex-col" data-theme="light">
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        onClick={() => setShowMenuMobile(!showMenuMobile)}
      >
        <span className="sr-only">Open sidebar</span>
        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
        </svg>
      </button>

      {location.pathname.startsWith(RouteList.baseUrl) && (
        <aside
          id="default-sidebar"
          className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform sm:translate-x-0 ${showMenuMobile ? 'transform-none' : '-translate-x-full'}`}
          aria-label="Sidebar"
          role="dialog"
        >
          <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
            <ul className="space-y-2 font-medium">
              <li>
                <div className="relative flex items-center p-2 text-gray-900 rounded-lg bg-gray-950 group">
                  <img src="https://cdn.prod.website-files.com/64cf6139edbdf8e614ebf87b/6505f4af00c14d13e96783b6_Faceless-Niches-Logo.svg" alt="" />
                  <span className='absolute text-black right-0 -bottom-4 text-xs dark:text-white'>Admin</span>
                </div>
              </li>
              {menuItems.map(item => (
                <li key={item.to}>
                  <Link
                    to={item.to}
                    className={`flex items-center p-2 rounded-lg dark:text-white group ${location.pathname === item.to ? 'bg-gray-100 dark:bg-gray-700' : 'text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700'}`}
                    onClick={item.action ? item.action : undefined}
                  >
                    {item.icon}
                    <span className="ms-3">{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </aside>
      )}

      {showMenuMobile && (
        <div
          drawer-backdrop=""
          className="md:hidden bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30"
          onClick={() => setShowMenuMobile(!showMenuMobile)}
        ></div>
      )}

      <div className={location.pathname.startsWith(RouteList.baseUrl) ? "p-4 sm:ml-64" : "p-4"}>
        <Routes>
          <Route path={RouteList.home} element={<Home />} />
          <Route path={RouteList.tableList} element={<TableSizes />} />
          <Route path={RouteList.environment} element={<Environment />} />
          <Route path={RouteList.tasks} element={<TaskDashboard />} />
          <Route path={RouteList.showTable} element={<TableDetails />} />
          <Route path="*" element={<Navigate to={'/error'} />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/fn/register" element={<Register />} />
          <Route path="/fn/login" element={<Login />} />
          <Route path="/fn/reset-password" element={<ResetPassword />} /> {/* Add this line */}
          <Route path="*" element={
            <ProtectedRoute>
              <Routes>
                <Route path="/*" element={<Main />} />
              </Routes>
            </ProtectedRoute>
          } />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<Navigate to={RouteList.error} replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
