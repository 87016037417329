export const RouteList = {
  baseUrl: '/facelessniches',
  home: '/facelessniches',
  tableList: '/facelessniches/table/list',
  environment: '/facelessniches/environment',
  tasks: '/facelessniches/tasks',
  showTable: '/facelessniches/table/show/:tableName',
  showTableAction: '/facelessniches/table/show/',
  error: '/error',






};

export const ApiList = {

  login: '/admin/login',
  register: '/admin/register',
  resetPassword: '/admin/reset-password',
  showTable: '/admin/table/',
  environment: '/admin/environment',
  thisTable: '/admin/table/show/',
  listTables: '/admin/table/list',
  scheduleTask: '/admin/tasks/schedule',
  scheduleStop: '/admin/tasks/stop',
  listTasks: '/admin/tasks',
  listScheduledTasks: '/admin/tasks/scheduled',
  thisTask: '/admin/tasks/'
}