import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, CircularProgress, LinearProgress, Grid, Box } from '@mui/material';

interface CpuUsage {
  core: number;
  usage: string;
}

interface DiskUsage {
  total: number;
  free: number;
  used: number;
  usagePercentage: string;
}

interface ServerStatsData {
  memoryUsage: string;
  cpuUsage: CpuUsage[];
  diskUsage: DiskUsage;
}

const ServerStats: React.FC = () => {
  const [data, setData] = useState<ServerStatsData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/admin/server_stats');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching server stats:', error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 10000); // fetch every 5 seconds

    return () => clearInterval(intervalId); // cleanup interval on component unmount
  }, []);

  if (!data) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              
              <Typography variant="h6">Memory Usage</Typography>
              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress variant="determinate" value={parseFloat(data.memoryUsage)} />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">{`${data.memoryUsage}%`}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">CPU Usage</Typography>
              <Grid container spacing={2}>
                {data.cpuUsage.map((cpu) => (
                  <Grid item xs={6} sm={3} key={cpu.core}>
                    <Box textAlign="center">
                      <Typography variant="subtitle1">Core {cpu.core}</Typography>
                      <CircularProgress
                        variant="determinate"
                        value={parseFloat(cpu.usage)}
                        size={100}
                        thickness={4}
                      />
                      <Typography variant="body2" color="textSecondary">{`${cpu.usage}%`}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Disk Usage</Typography>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box width="100%" mb={2}>
                  <LinearProgress variant="determinate" value={(data.diskUsage.used / data.diskUsage.total) * 100} />
                </Box>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography variant="body2">Total: {(data.diskUsage.total / (1024 ** 3)).toFixed(2)} GB</Typography>
                  <Typography variant="body2">Free: {(data.diskUsage.free / (1024 ** 3)).toFixed(2)} GB</Typography>
                  <Typography variant="body2">Used: {(data.diskUsage.used / (1024 ** 3)).toFixed(2)} GB</Typography>
                  <Typography variant="body2">Usage: {data.diskUsage.usagePercentage}%</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServerStats;
