import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface ApiCallData {
  endpoint: string;
  count: number;
}

const ApiCallsByEndpointTable: React.FC = () => {
  const [data, setData] = useState<ApiCallData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/admin/api_calls_by_endpoint');
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching API call data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="overflow-x-auto">
      <table className="table ">
        <thead>
          <tr>
            <th>Endpoint</th>
            <th>Number of API Calls</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="hover">
              <td>{item.endpoint}</td>
              <td>{item.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ApiCallsByEndpointTable;
