import React from 'react';
import SearchPerformanceChart from './Analytics/SearchPerformanceChart';
import ApiCallsByEndpointTable from './Analytics/ApiCallsByEndpointTable';
import ServerStats from './Analytics/ServerStats';
import { Typography } from '@mui/material';
import TaskErrorsTable from './Analytics/TaskErrorsTable';

const Home = () => {
  const components = [
    { component: <SearchPerformanceChart />, title: "Search Performance Chart" },
    { component: <TaskErrorsTable />, title: "Task Errors Table" },
    { component: <ServerStats />, title: "Server Stats" },
    { component: <ApiCallsByEndpointTable />, title: "API Calls By Endpoint" },
  ];

  return (
    <div className="container mx-auto p-2 md:p-4">
      <Typography variant="h4" component="h1" gutterBottom className="text-center">
        Server Dashboard
      </Typography>
      <div className="columns-1 md:columns-1 lg:columns-2 gap-4 space-y-4">
        {components.map((item, index) => (
          <div className="break-inside-avoid" key={index}>
            <div className="card bg-base-100 shadow-xl rounded-lg p-2 md:px-4 py-6">
              <Typography variant="h6" gutterBottom>
                {item.title}
              </Typography>
              {item.component}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
