import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';

interface TaskError {
  id: number;
  caused_by: string;
  error_message: string;
  created_at: string;
  updated_at: string;
}

const TaskErrorsTable = () => {
  const [errors, setErrors] = useState<TaskError[]>([]);
  const [selectedError, setSelectedError] = useState<TaskError | null>(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchErrors = async () => {
      try {
        const response = await fetch('/admin/latest_task_errors');
        const result = await response.json();
        setErrors(result);
      } catch (error) {
        console.error('Error fetching latest errors:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchErrors();
  }, []);

  const handleClickOpen = (error: TaskError) => {
    setSelectedError(error);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedError(null);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Caused By</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.map((error) => (
              <TableRow key={error.id}>
                <TableCell>{error.id}</TableCell>
                <TableCell>{error.caused_by}</TableCell>
                <TableCell>{new Date(error.created_at).toLocaleString()}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleClickOpen(error)}>
                    <Visibility />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Error Message</DialogTitle>
        <DialogContent>
          <pre>{selectedError && JSON.stringify(selectedError.error_message, null, 2)}</pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TaskErrorsTable;
