import React, { useState, useEffect } from 'react';
import { ApiList } from '../RouteList';

const Environment = () => {
  const [youtubeApiToken, setYoutubeApiToken] = useState('');
  const [keywordApiToken, setKeywordApiToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetch(ApiList.environment)
      .then(response => response.json())
      .then(data => {
        setYoutubeApiToken(data.youtube_api_token || '');
        setKeywordApiToken(data.ninja_random_words_api_token || '');
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching environment variables:', error);
        setLoading(false);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(ApiList.environment, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ youtube_api_token: youtubeApiToken, keyword_api_token: keywordApiToken })
    })
      .then(response => response.json())
      .then(data => {
        setMessage(data.message);
      })
      .catch(error => {
        console.error('Error updating environment variables:', error);
      });
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen"><p>Loading...</p></div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Environment Variables</h1>
      {message && <p className="mb-4 text-green-500">{message}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">YouTube API Token</label>
          <input
            type="text"
            value={youtubeApiToken}
            onChange={(e) => setYoutubeApiToken(e.target.value)}
            className="input input-bordered w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Ninja Random Keyword API Token</label>
          <input
            type="text"
            value={keywordApiToken}
            onChange={(e) => setKeywordApiToken(e.target.value)}
            className="input input-bordered w-full"
          />
        </div>
        <button type="submit" className="btn btn-primary">Save</button>
      </form>
    </div>
  );
};

export default Environment;
